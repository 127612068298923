import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cx from 'classnames';

const styles = theme => ({
  title: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    alignSelf: 'stretch',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'white',
  },
  arrowIcon: {
    transition: 'transform 0.25s',
  },
  flippedArrowIcon: {
    transform: 'rotate(180deg)',
  },
});

function DropdownToggler({ classes, open, onClick, title }) {
  return (
    <Typography variant="body1" className={classes.title} onClick={onClick}>
      {title}{' '}
      {open ? (
        <KeyboardArrowDownIcon
          className={cx(classes.arrowIcon, classes.flippedArrowIcon)}
        />
      ) : (
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      )}
    </Typography>
  );
}

DropdownToggler.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(DropdownToggler);
