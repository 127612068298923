import { Attr, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Permission from './permission';
import RolePermissionAssignment from './role_permission_assignment';

@Model()
export default class Role extends ApplicationRecord {
  static jsonapiType = 'roles';
  @Attr() name: string;
  @Attr() description: string;
  @Attr() level: number;

  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @HasMany() permissions: Permission[];
  @HasMany() users: Permission[];
  @HasMany() rolePermissionAssignments: RolePermissionAssignment[];

  isEmpty() {
    return this.permissions.length === 0;
  }

  isAuthorized(permissionName: string) {
    return this.permissions.find(v => v.name === permissionName);
  }
}
