import { createRouteBundle } from 'redux-bundler';
import React from 'react';

import Licenses from '../../components/pages/licenses';
import Templates from '../../components/pages/templates';
import lazy_with_reload from './lazy_with_reload';

const Admin = lazy_with_reload(() => import(/* webpackChunkName: "Admin" */ '../../components/pages/admin'));
const Accounts = lazy_with_reload(() => import(/* webpackChunkName: "Admin" */ '../../components/pages/accounts'));
const AccountDetail = lazy_with_reload(() => import(/* webpackChunkName: "AccountDetail" */ '../../components/pages/account_detail'));
const ChefPackageDetail = lazy_with_reload(() => import(/* webpackChunkName: "ChefPackageDetail" */ '../../components/pages/chef_package_detail'));
const ChefPackages = lazy_with_reload(() => import(/* webpackChunkName: "ChefPackages" */ '../../components/pages/chef_packages'));

const ClientDetail = lazy_with_reload(() => import(/* webpackChunkName: "ClientDetail" */ '../../components/pages/client_detail'));
const ClientListPage = lazy_with_reload(() => import(/* webpackChunkName: "ClientListPage" */ '../../components/pages/client_list_page'));
const Clients = lazy_with_reload(() => import(/* webpackChunkName: "Clients" */ '../../components/pages/clients'));
const Dashboard = lazy_with_reload(() => import(/* webpackChunkName: "Dashboard" */ '../../components/pages/dashboard'));
const EditChefPackage = lazy_with_reload(() => import(/* webpackChunkName: "EditChefPackage" */ '../../components/pages/edit_chef_package'));
const EditFacility = lazy_with_reload(() => import(/* webpackChunkName: "EditFacility" */ '../../components/pages/edit_facility'));
const EditGroup = lazy_with_reload(() => import(/* webpackChunkName: "EditGroup" */ '../../components/pages/edit_group'));
const EditImagePipeline = lazy_with_reload(() => import(/* webpackChunkName: "EditImagePipeline" */ '../../components/pages/edit_image_pipeline'));
const EditTeam = lazy_with_reload(() => import(/* webpackChunkName: "EditTeam" */ '../../components/pages/edit_team'));
const EditTemplate = lazy_with_reload(() => import(/* webpackChunkName: "EditTemplate" */ '../../components/pages/edit_template'));
const EditUser = lazy_with_reload(() => import(/* webpackChunkName: "EditUser" */ '../../components/pages/edit_user'));
const EditRole = lazy_with_reload(() => import(/* webpackChunkName: "EditRole" */ '../../components/pages/edit_role'));
const FacilityDetail = lazy_with_reload(() => import(/* webpackChunkName: "FacilityDetail" */ '../../components/pages/facility_detail'));
const GroupDetail = lazy_with_reload(() => import(/* webpackChunkName: "GroupDetail" */ '../../components/pages/group_detail'));
const Groups = lazy_with_reload(() => import(/* webpackChunkName: "Groups" */ '../../components/pages/groups'));
const ImagePipelineDetail = lazy_with_reload(() => import(/* webpackChunkName: "ImagePipelineDetail" */ '../../components/pages/image_pipeline_detail'));
const ImagePipelineSyncs = lazy_with_reload(() => import(/* webpackChunkName: "ImagePipelineSyncs" */ '../../components/pages/image_pipeline_detail/ImagePipelineSyncs'));
const ImagePipelines = lazy_with_reload(() => import(/* webpackChunkName: "ImagePipelines" */ '../../components/pages/image_pipelines'));
const Images = lazy_with_reload(() => import(/* webpackChunkName: "Images" */ '../../components/pages/images'));
const NewAccount = lazy_with_reload(() => import(/* webpackChunkName: "NewAccount" */ '../../components/pages/new_account'));
const NewChefPackage = lazy_with_reload(() => import(/* webpackChunkName: "NewChefPackage" */ '../../components/pages/new_chef_package'));
const NewClient = lazy_with_reload(() => import(/* webpackChunkName: "NewClient" */ '../../components/pages/clients/NewClient'));
const NewFacility = lazy_with_reload(() => import(/* webpackChunkName: "NewFacility" */ '../../components/pages/new_facility'));
const NewGroup = lazy_with_reload(() => import(/* webpackChunkName: "NewGroup" */ '../../components/pages/new_group'));
const NewImagePipeline = lazy_with_reload(() => import(/* webpackChunkName: "NewImagePipeline" */ '../../components/pages/new_image_pipeline'));
const NewRole = lazy_with_reload(() => import(/* webpackChunkName: "NewRole" */ '../../components/pages/new_role'));
const NewTeam = lazy_with_reload(() => import(/* webpackChunkName: "NewTeam" */ '../../components/pages/new_team'));
const NewTemplate = lazy_with_reload(() => import(/* webpackChunkName: "NewTemplate" */ '../../components/pages/new_template'));
const NewUser = lazy_with_reload(() => import(/* webpackChunkName: "NewUser" */ '../../components/pages/new_user'));
const NewWorkstation = lazy_with_reload(() => import(/* webpackChunkName: "NewWorkstation" */ '../../components/pages/new_workstation'));
const Render = lazy_with_reload(() => import(/* webpackChunkName: "Render" */ '../../components/pages/render'));
const RoleDetail = lazy_with_reload(() => import(/* webpackChunkName: "RoleDetail" */ '../../components/pages/role_detail'));
const Roles = lazy_with_reload(() => import(/* webpackChunkName: "Roles" */ '../../components/pages/roles'));
const Storage = lazy_with_reload(() => import(/* webpackChunkName: "Storage" */ '../../components/pages/storage'));
const TeamDetail = lazy_with_reload(() => import(/* webpackChunkName: "TeamDetail" */ '../../components/pages/team_detail'));
const Teams = lazy_with_reload(() => import(/* webpackChunkName: "Teams" */ '../../components/pages/teams'));
const TemplateDetail = lazy_with_reload(() => import(/* webpackChunkName: "TemplateDetail" */ '../../components/pages/template_detail'));
const UserDetail = lazy_with_reload(() => import(/* webpackChunkName: "UserDetail" */ '../../components/pages/user_detail'));
const Users = lazy_with_reload(() => import(/* webpackChunkName: "Users" */ '../../components/pages/users'));
const WorkstationDetail = lazy_with_reload(() => import(/* webpackChunkName: "WorkstationDetail" */ '../../components/pages/workstation_detail'));
const Workstations = lazy_with_reload(() => import(/* webpackChunkName: "Workstations" */ '../../components/pages/workstations'));

const route = (component, drawItem, options = {}) => ({
  component,
  drawItem,
  options,
});

export default createRouteBundle({
  '/': route(ClientListPage, 'dashboard', { menu: false, apis: 'dashboard_stats' }),
  '/admin': route(Admin, 'admin', { menu: false }),
  '/roles': route(Roles, 'roles'),
  '/workstations': route(Workstations, 'workstations'),
  '/images': route(Images, 'images'),

  // Clients
  '/clients': route(Clients, 'clients', { menu: false }),
  '/clients/new': route(NewClient, 'clients'),
  '/clients/:id/detail': route(ClientDetail, 'clients'),
  '/clients/:id': route(Dashboard, 'dashboard'),

  // Workstations
  '/clients/:id/facilities/:facilityId/workstations': route(Workstations, 'workstations', {
    apis: ['workstations', 'groups', 'teams', 'templates'],
  }),

  '/clients/:id/facilities/:facilityId/render': route(Workstations, 'render', {
    apis: ['workstations', 'groups', 'teams', 'render', 'templates'],
  }),
  '/clients/:id/facilities/:facilityId/workstations/new': route(NewWorkstation, 'workstations', {
    apis: ['groups', 'templates'],
  }),
  '/clients/:id/facilities/:facilityId/workstations/:workstationId': route(WorkstationDetail, 'workstations', {
    apis: ['groups', 'workstationStats', 'workstation', 'workstationEvents', 'licenses'],
  }),

  '/clients/:id/facilities/:facilityId/render/new': route(NewWorkstation, 'render', {
    apis: ['groups', 'render', 'templates'],
  }),

  '/clients/:id/facilities/:facilityId/render/:workstationId': route(WorkstationDetail, 'render', {
    apis: ['groups', 'workstationStats', 'render', 'workstation', 'workstationEvents'],
  }),

  // Templates

  '/clients/:id/facilities/:facilityId/templates/new': route(NewTemplate, 'templates', {
    apis: 'vpcs,subnets,availabilityZones,keypairs,securityGroups,images,imagePipelines',
  }),
  '/clients/:id/facilities/:facilityId/templates/:templateId': route(TemplateDetail, 'templates'),
  '/clients/:id/facilities/:facilityId/templates/:templateId/edit': route(EditTemplate, 'templates', {
    apis: 'vpcs,subnets,availabilityZones,keypairs,securityGroups,images,imagePipelines',
  }),

  '/clients/:id/facilities/:facilityId/templates': route(Templates, 'templates', {
    apis: ['templates'],
  }),

  // Chef Packages
  '/clients/:id/chef_packages': route(ChefPackages, 'chef_packages', { apis: 'chefPackages' }),
  '/clients/:id/chef_packages/new': route(NewChefPackage, 'chef_packages'),
  '/clients/:id/chef_packages/:chefPackageId': route(ChefPackageDetail, 'chef_packages'),
  '/clients/:id/chef_packages/:chefPackageId/edit': route(EditChefPackage, 'chef_packages'),

  '/clients/:id/facilities/:facilityId/chef_packages': route(ChefPackages, 'chef_packages', { apis: 'chefPackages' }),
  '/clients/:id/facilities/:facilityId/chef_packages/new': route(NewChefPackage, 'chef_packages'),
  '/clients/:id/facilities/:facilityId/chef_packages/:chefPackageId': route(ChefPackageDetail, 'chef_packages'),
  '/clients/:id/facilities/:facilityId/chef_packages/:chefPackageId/edit': route(EditChefPackage, 'chef_packages'),

  // Images
  '/clients/:id/images': route(Images, 'images', { apis: 'images' }),

  '/clients/:id/licenses': route(Licenses, 'licenses', { apis: 'licenses' }),
  '/clients/:id/facilities/:facilityId/licenses': route(Licenses, 'licenses', { apis: 'licenses' }),
  // '/clients/:id/images/new': route(NewImage, 'images'),
  // '/clients/:id/images/:chefPackageId': route(ImageDetail, 'images'),
  // '/clients/:id/images/:chefPackageId/edit': route(EditImage, 'images'),

  '/clients/:id/facilities/:facilityId/images': route(Images, 'images', { apis: 'images' }),
  // '/clients/:id/facilities/:facilityId/images/new': route(NewImage, 'images'),
  // '/clients/:id/facilities/:facilityId/images/:chefPackageId': route(ImageDetail, 'images'),
  // '/clients/:id/facilities/:facilityId/images/:chefPackageId/edit': route(EditImage, 'images'),

  '/clients/:id/facilities/:facilityId/storage': route(Storage, 'storage'),

  // Image Pipelines
  '/clients/:id/facilities/:facilityId/image_pipelines': route(ImagePipelines, 'image_pipelines', { apis: 'imagePipelines' }),
  '/clients/:id/facilities/:facilityId/image_pipelines/new': route(NewImagePipeline, 'image_pipelines', { apis: 'imagePipelines,allChefPackages,vpcs,subnets,availabilityZones,keypairs' }),
  '/clients/:id/facilities/:facilityId/image_pipelines/:imagePipelineId': route(ImagePipelineDetail, 'image_pipelines'),

  '/clients/:id/facilities/:facilityId/image_pipelines/:imagePipelineId/syncs': route(ImagePipelineSyncs, 'image_pipelines', { apis: 'imagePipelineSyncs' }),
  '/clients/:id/facilities/:facilityId/image_pipelines/:imagePipelineId/edit': route(EditImagePipeline, 'image_pipelines', {
    apis: 'imagePipelines,allChefPackages,vpcs,subnets,availabilityZones,keypairs',
  }),

  // Facilities
  '/clients/:id/facilities/new': route(NewFacility, 'facilities', { apis: 'directories,teams' }),
  '/clients/:id/facilities/:facilityId': route(FacilityDetail, 'dashboard'),
  '/clients/:id/facilities/:facilityId/edit': route(EditFacility, 'dashboard'),

  // Roles
  '/clients/:id/roles': route(Roles, 'roles', { apis: 'roles' }),
  '/clients/:id/roles/new': route(NewRole, 'roles'),
  '/clients/:id/roles/:roleId': route(RoleDetail, 'roles', { apis: 'teams' }),
  '/clients/:id/roles/:roleId/edit': route(EditRole, 'roles'),

  '/clients/:id/facilities/:facilityId/roles': route(Roles, 'roles', { apis: 'roles' }),
  '/clients/:id/facilities/:facilityId/roles/new': route(NewRole, 'roles'),
  '/clients/:id/facilities/:facilityId/roles/:roleId': route(RoleDetail, 'roles', { apis: 'teams' }),
  '/clients/:id/facilities/:facilityId/roles/:roleId/edit': route(EditRole, 'roles'),

  // Accounts
  '/clients/:id/accounts': route(Accounts, 'accounts', { apis: 'accounts' }),
  '/clients/:id/accounts/new': route(NewAccount, 'accounts'),
  '/clients/:id/accounts/:accountId': route(AccountDetail, 'accounts'),

  '/clients/:id/facilities/:facilityId/accounts': route(Accounts, 'accounts', { apis: 'accounts' }),
  '/clients/:id/facilities/:facilityId/accounts/new': route(NewAccount, 'accounts'),
  '/clients/:id/facilities/:facilityId/accounts/:accountId': route(AccountDetail, 'accounts'),

  // Users

  '/clients/:id/users': route(Users, 'users'),
  '/clients/:id/users/new': route(NewUser, 'users', { apis: 'teams,roles' }),
  '/clients/:id/users/:userId': route(UserDetail, 'users', { apis: 'teams,roles' }),
  '/clients/:id/users/:userId/edit': route(EditUser, 'users', { apis: 'teams,roles' }),

  '/clients/:id/facilities/:facilityId/users': route(Users, 'users'),
  '/clients/:id/facilities/:facilityId/users/new': route(NewUser, 'users', { apis: 'teams,roles' }),
  '/clients/:id/facilities/:facilityId/users/:userId': route(UserDetail, 'users', { apis: 'teams,roles' }),
  '/clients/:id/facilities/:facilityId/users/:userId/edit': route(EditUser, 'users', { apis: 'teams,roles' }),

  // Teams

  '/clients/:id/teams': route(Teams, 'teams', { apis: 'teams' }),
  '/clients/:id/teams/new': route(NewTeam, 'teams', { apis: 'teams' }),
  '/clients/:id/teams/:teamId': route(TeamDetail, 'teams', { apis: 'groups' }),
  '/clients/:id/teams/:teamId/edit': route(EditTeam, 'teams'),

  '/clients/:id/facilities/:facilityId/teams': route(Teams, 'teams', { apis: 'teams' }),
  '/clients/:id/facilities/:facilityId/teams/new': route(NewTeam, 'teams', { apis: 'teams' }),
  '/clients/:id/facilities/:facilityId/teams/:teamId': route(TeamDetail, 'teams', { apis: 'groups' }),
  '/clients/:id/facilities/:facilityId/teams/:teamId/edit': route(EditTeam, 'teams'),

  // Groups

  '/clients/:id/facilities/:facilityId/groups': route(Groups, 'groups', { apis: 'groups' }),
  '/clients/:id/facilities/:facilityId/groups/new': route(NewGroup, 'groups'),
  '/clients/:id/facilities/:facilityId/groups/:groupId': route(GroupDetail, 'groups', { apis: 'teams' }),
  '/clients/:id/facilities/:facilityId/groups/:groupId/edit': route(EditGroup, 'groups'),
});
