import { IconButton, Menu, withStyles } from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Client from '../../../models/client';
import ClientPrivacyMask from '../../atoms/ClientPrivacyMask';
import DropdownHeader from '../../molecules/DropdownHeader';
import DropdownItem from '../../molecules/DropdownItem';
import DropdownToggler from '../../molecules/DropdownToggler';

const StyledMenu = withStyles({
  paper: {
    padding: 0,
    borderRadius: 0,
    minWidth: 300,
  },
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    elevation={4}
    {...props}
  />
));

function ClientPicker({ clientData, activeClient }) {
  const [anchorEl, setAnchor] = useState(null);

  return (
    <>
      <DropdownToggler
        open={Boolean(anchorEl)}
        onClick={e => setAnchor(e.currentTarget)}
        title={
          activeClient ? (
            <ClientPrivacyMask client={activeClient} />
          ) : (
            'Select Client'
          )
        }
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchor(null)}
      >
        <DropdownHeader
          title="Select client"
          rightChild={
            <IconButton onClick={() => setAnchor(null)} size="small">
              <CloseIcon />
            </IconButton>
          }
        />
        {clientData.map(client => (
          <DropdownItem
            title={<ClientPrivacyMask client={client} />}
            text={`${client.facilityCount} Facilities, ${client.userCount} Users`}
            to={`/clients/${client.id}`}
            onClick={() => setAnchor(null)}
          />
        ))}
      </StyledMenu>
    </>
  );
}

ClientPicker.propTypes = {
  clientData: PropTypes.arrayOf(Client).isRequired,
  activeClient: PropTypes.instanceOf(Client).isRequired,
};

export default connect('selectClientData', 'selectActiveClient', ClientPicker);
