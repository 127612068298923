import './logrocket';
import './sentry';
import './App.css';

import { Provider, connect } from 'redux-bundler-react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AuthenticationCheck from './atoms/AuthenticationCheck';
import Layout from './Layout';
import PusherContainer from './atoms/PusherContainer';

import getStore from '../bundles';

const App = () => {
  return (
    <Provider store={getStore()}>
      <AuthenticationCheck>
        {/* eslint-disable-next-line no-unused-vars */}
        {user => (
          <PusherContainer>
            <Layout />
          </PusherContainer>
        )}
      </AuthenticationCheck>
    </Provider>
  );
};

export default App;
