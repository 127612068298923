import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

// eslint-disable-next-line no-unused-vars
import AwsAccount from '../models/aws_account';
import Account from '../models/account';
// eslint-disable-next-line no-unused-vars
import Region from '../models/region';
import CrossAccountRoleInstallation from '../models/cross_account_role_installation';

export default {
  name: 'accounts',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      activeAccount: null,
      loadingActiveAccount: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          activeAccount: null,
          items: null,
        };
      }
      if (type === 'FETCH_ACCOUNTS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_ACCOUNTS_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }
      if (type === 'FETCH_ACCOUNT_START') {
        return { ...state, loadingActiveAccount: true };
      }
      if (type === 'FETCH_ACCOUNT_SUCCESS') {
        return {
          ...state,
          loadingActiveAccount: false,
          activeAccount: payload.result,
        };
      }

      if (type === 'REMOVE_ACCOUNT') {
        const index = state.data.findIndex(u => u.id === payload.account.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      return state;
    };
  },

  doFetchAccount: clientId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_ACCOUNT_START' });

    const response = await Account.includes([
      'regions',
      'cross_account_role_installation',
    ]).find(clientId);
    dispatch({
      type: 'FETCH_ACCOUNT_SUCCESS',
      payload: { result: response.data },
    });
  },

  selectAccountsRaw: state => state.accounts,
  selectActiveAccount: createSelector(
    'selectAccountsRaw',
    accountData => accountData.activeAccount,
  ),

  doFetchAccounts: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const { sortBy } = state.accounts;
    const { sortDirection } = state.accounts;

    const client = store.selectActiveClient(state);
    dispatch({ type: 'FETCH_ACCOUNTS' });
    const scope = Account.where({
      kept: true,
      client_id: client.id,
    }).order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_ACCOUNTS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateCrossAccountRoleInstallation: () => async ({ store, getState }) => {
    const state = getState();
    const account = store.selectActiveAccount(state);

    const cari = new CrossAccountRoleInstallation({ account });

    try {
      const result = await cari.save({ with: ['account'] });
      if (result) {
        store.doFetchAccount(account.id);
      }

      return cari;
    } catch (err) {
      return false;
    }
  },

  doDestroyAccount: account => async ({ dispatch }) => {
    await account.destroy();
    dispatch({ type: 'REMOVE_ACCOUNT', payload: { account } });
  },

  reactShouldFetchActiveAccount: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveAccount',
    'selectAccountsRaw',
    (routeParams, pathname, activeAccount, accountData) => {
      if (
        !pathname.includes('/accounts') ||
        !routeParams.accountId ||
        accountData.loadingActiveAccount
      ) {
        return null;
      }
      if (activeAccount && activeAccount.id === routeParams.accountId) {
        return null;
      }

      return { actionCreator: 'doFetchAccount', args: [routeParams.accountId] };
    },
  ),

  reactShouldFetchAccounts: createSelector(
    'selectRouteApis',
    'selectAccountsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, accountsRaw, currentUser, activeClient) => {
      const wantsAccounts = apis.includes('accounts');
      if (
        !wantsAccounts ||
        accountsRaw.loading ||
        accountsRaw.items ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchAccounts' };
    },
  ),

  selectAccountsRaw: state => state.accounts,
  selectAccounts: state => state.accounts.items || [],
  selectAccountsLoading: state => state.accounts.loading,
  selectAccountsSortBy: state => state.accounts.sortBy,
  selectAccountsSortDirection: state => state.accounts.sortDirection,
};
