import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Team from '../models/team';
import TeamGroupAssignment from '../models/team_group_assignment';
import TeamMembership from '../models/team_membership';

export default {
  name: 'teams',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeTeam: null,
      loadingActiveTeam: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_TEAM_START') {
        return { ...state, loadingActiveTeam: true };
      }
      if (type === 'FETCH_TEAM_SUCCESS') {
        return {
          ...state,
          loadingActiveTeam: false,
          activeTeam: payload.result,
        };
      }
      if (type === 'FETCH_TEAMS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_TEAMS_SUCCESS') {
        return { ...state, loading: false, data: payload.results };
      }
      if (type === 'CHANGE_TEAM_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_TEAM') {
        return { ...state, data: state.data.concat([payload.team]) };
      }
      if (type === 'UPDATE_TEAM') {
        if (state.data) {
          const index = state.data.findIndex(u => u.id === payload.team.id);
          const { team } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([team])
              .concat(state.data.slice(index + 1)),
            activeTeam:
              state.activeTeam && state.activeTeam.id === payload.team.id
                ? team
                : state.activeTeam,
          };
        }

        return {
          ...state,
          activeTeam: payload.team,
        };
      }
      if (type === 'TOGGLE_TEAM_SELECTION') {
        const index = state.data.findIndex(u => u.id === payload.team.id);
        const { team } = payload;
        team.selected = !team.selected;
        team.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([team])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_TEAM') {
        const index = state.data.findIndex(u => u.id === payload.team.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_TEAM') {
        return {
          ...state,
          activeTeam: null,
        };
      }

      return state;
    };
  },

  doResetTeam: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_TEAM' });
  },

  doFetchTeams: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.teams;
    const { sortDirection } = state.teams;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_TEAMS_START' });
    const response = await Team.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({ client_id: client.id })
      .all();
    dispatch({
      type: 'FETCH_TEAMS_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchActiveTeam: teamId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_TEAM_START' });

    const response = await Team.includes(['users', 'groups'])
      .order('users.first_name, users.last_name')
      .find(teamId);
    dispatch({
      type: 'FETCH_TEAM_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeTeamSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({ type: 'CHANGE_TEAM_SORT', payload: { sortDirection, sortBy } });
    store.doFetchTeams();
  },

  doToggleTeamSelection: team => ({ dispatch }) => {
    dispatch({ type: 'TOGGLE_TEAM_SELECTION', payload: { team } });
  },

  doCreateTeam: values => async ({ dispatch, store, state }) => {
    const client = store.selectActiveClient(state);
    const team = new Team({
      name: values.name,
      client,
    });

    const success = await team.save({
      with: ['client.id'],
    });
    if (success) {
      dispatch({ type: 'ADD_TEAM', payload: { team } });
    }
    return team;
  },

  doDestroyTeam: team => async ({ dispatch }) => {
    await team.destroy();
    dispatch({ type: 'REMOVE_TEAM', payload: { team } });
  },

  doUpdateTeam: values => async ({ dispatch, store }) => {
    const {
      teams: { activeTeam },
    } = store.getState();

    const team = activeTeam.dup();
    team.assignAttributes(values);

    const success = await team.save();
    if (success) {
      dispatch({
        type: 'UPDATE_TEAM',
        payload: { team },
      });
    }
    return team;
  },

  doAddUsersToTeam: (users, team) => async ({ store, getState }) => {
    const nextTeam = team.dup();

    const teamMemberships = users.map(user => new TeamMembership({ user }));

    nextTeam.teamMemberships = [
      ...nextTeam.teamMemberships,
      ...teamMemberships,
    ];

    await nextTeam.save({
      with: [{ teamMemberships: 'user.id' }],
    });

    const state = getState();
    const activeTeam = store.selectActiveTeam(state);
    if (activeTeam) {
      store.doFetchActiveTeam(activeTeam.id);
    }
  },

  doAddGroupsToTeam: (groups, team) => async ({ store, getState }) => {
    const nextTeam = team.dup();

    const teamGroupAssignments = groups.map(
      group => new TeamGroupAssignment({ group }),
    );

    nextTeam.teamGroupAssignments = [
      ...nextTeam.teamGroupAssignments,
      ...teamGroupAssignments,
    ];

    await nextTeam.save({
      with: [{ teamGroupAssignments: 'group.id' }],
    });

    const state = getState();
    const activeTeam = store.selectActiveTeam(state);
    if (activeTeam) {
      store.doFetchActiveTeam(activeTeam.id);
    }
  },

  selectTeamSortBy: state => state.teams.sortBy,
  selectTeamSortDirection: state => state.teams.sortDirection,
  selectTeamLoading: state => state.teams.loading,
  selectTeamRaw: state => state.teams,
  selectTeamData: state => state.teams.data || [],
  selectSelectedTeamCount: createSelector(
    'selectTeamData',
    teamData => teamData.filter(u => u.selected).length,
  ),
  selectSelectedTeams: createSelector(
    'selectTeamData',
    teamData => teamData.filter(u => u.selected),
  ),

  selectActiveTeamId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.teamId,
  ),
  selectActiveTeam: createSelector(
    'selectTeamRaw',
    teamData => teamData.activeTeam,
  ),
  reactShouldFetchTeam: createSelector(
    'selectRouteApis',
    'selectTeamRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, teamData, currentUser, activeClient) => {
      const wantsTeams = apis.includes('teams');
      if (
        !wantsTeams ||
        teamData.loading ||
        teamData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchTeams' };
    },
  ),
  reactShouldFetchActiveTeam: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveTeam',
    'selectTeamRaw',
    (routeParams, pathname, activeTeam, teamData) => {
      if (
        !pathname.includes('/teams') ||
        !routeParams.teamId ||
        teamData.loadingActiveTeam
      ) {
        return null;
      }
      if (activeTeam && activeTeam.id === routeParams.teamId) {
        return null;
      }

      return { actionCreator: 'doFetchActiveTeam', args: [routeParams.teamId] };
    },
  ),

  reactShouldResetTeam: createSelector(
    'selectActiveTeamId',
    'selectActiveTeam',
    (activeTeamId, activeTeam) => {
      if (!activeTeam) return null;
      if (activeTeam.id !== activeTeamId) {
        return { actionCreator: 'doResetTeam' };
      }

      return null;
    },
  ),
};
