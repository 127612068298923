import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import AvailabilityZone from './availability_zone';
import Client from './client';
import Facility from './facility';
import Image from './image';
import InstanceType from './instance_type';
import Keypair from './keypair';
import SecurityGroup from './security_group';
import Subnet from './subnet';
import TemplateSecurityGroupAssignment from './template_security_group_assignment';
import Vpc from './vpc';
import ImagePipeline from './image_pipeline';

@Model()
export default class Template extends ApplicationRecord {
  static jsonapiType = 'templates';
  @Attr() name: string;
  @Attr() shortName: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() ebsVolumeSize: number;
  @Attr() multiAz: boolean;
  @Attr() ebsRaid: boolean;
  @Attr() render: boolean;
  @Attr() useLatestImage: boolean;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() client: Client;
  @BelongsTo() facility: Facility;

  @HasOne() image: Image;
  @HasOne() imagePipeline: ImagePipeline;
  @HasOne() instanceType: InstanceType;
  @HasOne() keypair: Keypair;
  @HasOne() availabilityZone: AvailabilityZone;
  @HasOne() vpc: Vpc;
  @HasOne() subnet: Subnet;
  @HasMany()
  templateSecurityGroupAssignments: TemplateSecurityGroupAssignment[];

  @HasMany() securityGroups: SecurityGroup[];

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
