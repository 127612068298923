import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationCreation extends ApplicationRecord {
  static jsonapiType = 'workstation_creations';
  @Attr() state: string;

  @Attr() executionArn: string;
  @Attr() executionArnRegion: string;

  @BelongsTo() workstation: Workstation;

  stepFunctionUrl() {
    return `https://${this.executionArnRegion}.console.aws.amazon.com/states/home?region=${this.executionArnRegion}#/executions/details/${this.executionArn}`;
  }

  isPending() {
    return this.state === 'started' || this.state === 'pending';
  }
}
