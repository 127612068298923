import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';

import User from '../../../models/user';

const styles = {
  code: {
    font:
      '13px "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace',
    backgroundColor: '#073642',

    whiteSpace: 'pre',
    color: '#839496',

    padding: '20px',
    overflow: 'scroll',
    height: '100%',
    flexGrow: 1,
  },
  paper: {
    minHeight: '80vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  scroller: {
    width: 1,
  },

  icon: {
    verticalAlign: 'bottom',
  },
  inProgress: {
    paddingTop: '10px',
  },

  complete: {
    backgroundColor: '#1bc943',
  },
  statusContainer: {
    display: 'flex',
  },
  status: {
    marginRight: '10px',
  },
};

const RebuildDialog = ({
  classes,
  open,
  onClose,
  doRebuildFacility,
  activeUser,
  doCreateFacilityCreationPreview,
}) => {
  const [parameters, setParameters] = React.useState({});

  const handleConfirm = async () => {
    await doRebuildFacility(createChangeSet);
    setCreateChangeSet(true);

    onClose();
  };

  const loading = open;

  const [createChangeSet, setCreateChangeSet] = React.useState(true);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await doCreateFacilityCreationPreview();

      if (active) {
        setParameters(response.attributes.parameters);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onBackdropClick={onClose}
    >
      <DialogTitle>
        Initiate Facility Rebuild
        <Typography variant="body2">
          Facility rebuilds will re-execute the Facility Creation step function.
          This could cause downtime for the facility.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.code}>
          {parameters && JSON.stringify(parameters, null, 2)}
        </div>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          value="createChangeSet"
          control={
            <Switch
              checked={createChangeSet}
              onChange={e => setCreateChangeSet(e.target.checked)}
              name="createChangeSet"
            />
          }
          label="Create Change Set"
          labelPlacement="end"
        />
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="secondary">
          Begin
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RebuildDialog.propTypes = {
  activeUser: PropTypes.instanceOf(User).isRequired,

  doCreateMasquerade: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  connect(
    'selectActiveUser',
    'doRebuildFacility',
    'doCreateFacilityCreationPreview',
    RebuildDialog,
  ),
);
