import React, { Component } from 'react';
import { connect } from 'redux-bundler-react';
import Amplify, { Hub, Auth } from 'aws-amplify';

import LoginScreen from './LoginScreen';

function redirectUrl() {
  if (process.env.REACT_APP_CONTEXT === 'production') {
    return process.env.REACT_APP_URL;
  }

  return process.env.REACT_APP_DEPLOY_PRIME_URL;
}

function configureAmplify() {
  const oauth = {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: redirectUrl(),
    redirectSignOut: redirectUrl(),
    responseType: 'code',
  };

  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      region: process.env.REACT_APP_REGION,
      mandatorySignIn: true,
      oauth,
    },
  });
}

class AuthenticationCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: window.location.search.includes('code'),
      redirecting: false,
    };
  }

  async componentDidMount() {
    this.listenForAuth();
    configureAmplify();
    await this.verifyUser();
  }

  async verifyUser() {
    const { doUpdateUrl, doSetCurrentUser, pathname } = this.props;
    try {
      const user = await Auth.currentAuthenticatedUser();

      doSetCurrentUser(user);

      this.setState({ user });

      if (localStorage.getItem('redirectURL')) {
        this.setState({ redirecting: true });
        const url = localStorage.getItem('redirectURL');
        localStorage.removeItem('redirectURL');
        doUpdateUrl(url);
      }
    } catch (err) {
      this.setState({ user: null });
      localStorage.setItem('redirectURL', pathname);
      console.log(pathname);

      doSetCurrentUser(null);
      console.log('User not authenticated');
    }
  }

  listenForAuth() {
    const { doSetCurrentUser } = this.props;
    Hub.listen('auth', async data => {
      const { payload } = data;
      console.log(payload.event);
      if (payload.event === 'signIn') {
        const user = await Auth.currentUserPoolUser();

        doSetCurrentUser(user);
        this.setState({ user, waiting: false });
      }

      if (payload.event === 'signOut') {
        console.log('Logging out');

        doSetCurrentUser(null);
        this.setState({ user: null, waiting: false });
      }
    });
  }

  render() {
    const { children, classes } = this.props;
    const { redirecting, waiting, user } = this.state;
    if (user) {
      return children(user);
    }
    if (waiting || redirecting) {
      return null;
    }
    return (
      <LoginScreen
        onSigninClick={() => Auth.federatedSignIn({ provider: 'Arch' })}
      />
    );
  }
}

export default connect(
  'doUpdateUrl',
  'selectPathname',
  'doSetCurrentUser',
  AuthenticationCheck,
);
