import { Container, CssBaseline, Drawer, withStyles } from '@material-ui/core';
import { connect } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Client from '../../../models/client';
import FacilityToolbar from './FacilityToolbar';
import MainToolbar from '../../organisms/MainToolbar';

const drawerWidth = 240;
const drawerWidthMobile = 56;

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
  page: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: 'calc(100vh - 64px)',
    marginTop: 64,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: drawerWidthMobile,
      overflowX: 'hidden',
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  },
});

class ArchPage extends Component {
  render() {
    const {
      children,
      classes,
      doUpdateUrl,
      drawerMenu,
      activeClient,
      activeFacility,
    } = this.props;
    const DrawerMenu = drawerMenu || null;
    return (
      <div className={classes.root} onClick={getNavHelper(doUpdateUrl)}>
        <CssBaseline />
        <MainToolbar />
        {DrawerMenu && (
          <Drawer
            variant="persistent"
            open={Boolean(activeClient)}
            classes={{ paper: classes.drawerPaper }}
          >
            {/* <div className={classes.toolbarIcon} /> */}
            {DrawerMenu && <DrawerMenu />}
          </Drawer>
        )}

        <div className={classes.page}>
          <div className={classes.appBarSpacer} />

          {activeClient && activeFacility && <FacilityToolbar />}
          <Container maxWidth={false} className={classes.container}>
            {children}
          </Container>
        </div>
      </div>
    );
  }
}

ArchPage.propTypes = {
  children: PropTypes.node.isRequired,
  doUpdateUrl: PropTypes.func.isRequired,
  drawerMenu: PropTypes.instanceOf(Component),
  activeClient: PropTypes.instanceOf(Client).isRequired,
};

ArchPage.defaultProps = {
  drawerMenu: null,
};

export default connect(
  'doUpdateUrl',
  'selectActiveClient',
  'selectActiveFacility',
  withStyles(styles, { withTheme: true })(ArchPage),
);
