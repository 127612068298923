import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import ImageDefinition from '../models/image_definition';
import ImagePipeline from '../models/image_pipeline';
import ImagePipelineSync from '../models/image_pipeline_sync';
import ImagePipelineSyncTransition from '../models/image_pipeline_sync_transition';
import ImagePipelineSyncCancellation from
  '../models/image_pipeline_sync_cancellation';

// import ImagePipelineSync from '../models/image_pipeline_sync';

export default {
  name: 'imagePipelines',
  getReducer: () => {
    const initialData = {
      data: null,
      syncData: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeImagePipeline: null,
      loadingActiveImagePipeline: null,
      loadingSyncs: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_IMAGE_PIPELINE_SYNCS_START') {
        return { ...state, loadingSyncs: true };
      }
      if (type === 'FETCH_IMAGE_PIPELINE_SYNCS_SUCCESS') {
        return {
          ...state,
          loadingSyncs: false,
          syncData: payload.results,
        };
      }
      if (type === 'FETCH_IMAGE_PIPELINE_START') {
        return { ...state, loadingActiveImagePipeline: true };
      }
      if (type === 'FETCH_IMAGE_PIPELINE_SUCCESS') {
        return {
          ...state,
          loadingActiveImagePipeline: false,
          activeImagePipeline: payload.result,
        };
      }
      if (type === 'FETCH_IMAGE_PIPELINES_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_IMAGE_PIPELINES_SUCCESS') {
        return { ...state, loading: false, data: payload.results };
      }
      if (type === 'CHANGE_IMAGE_PIPELINE_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_IMAGE_PIPELINE') {
        return {
          ...state,
          data: state.data
            ? state.data.concat([payload.imagePipeline])
            : [payload.imagePipeline],
        };
      }
      if (type === 'UPDATE_IMAGE_PIPELINE') {
        if (state.data) {
          const index = state.data.findIndex(
            u => u.id === payload.imagePipeline.id,
          );
          const { imagePipeline } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([imagePipeline])
              .concat(state.data.slice(index + 1)),
            activeImagePipeline:
              state.activeImagePipeline &&
              state.activeImagePipeline.id === payload.imagePipeline.id
                ? imagePipeline
                : state.activeImagePipeline,
          };
        }

        return {
          ...state,
          activeImagePipeline: payload.imagePipeline,
        };
      }
      if (type === 'TOGGLE_IMAGE_PIPELINE_SELECTION') {
        const index = state.data.findIndex(
          u => u.id === payload.imagePipeline.id,
        );
        const { imagePipeline } = payload;
        imagePipeline.selected = !imagePipeline.selected;
        imagePipeline.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([imagePipeline])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_IMAGE_PIPELINE') {
        const index =
          state.data &&
          state.data.findIndex(u => u.id === payload.imagePipeline.id);
        return {
          ...state,
          data: state.data
            ? state.data.slice(0, index).concat(state.data.slice(index + 1))
            : null,
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_IMAGE_PIPELINE') {
        return {
          ...state,
          activeImagePipeline: null,
        };
      }

      return state;
    };
  },

  doResetImagePipeline: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_IMAGE_PIPELINE' });
  },

  doFetchImagePipelines: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.imagePipelines;
    const { sortDirection } = state.imagePipelines;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_IMAGE_PIPELINES_START' });
    const response = await ImagePipeline.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .includes([
        { facility: 'region' },
        'chef_package',
        'last_image_pipeline_sync',
      ])
      .where({
        client_id: client.id,
      })
      .all();
    dispatch({
      type: 'FETCH_IMAGE_PIPELINES_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchImagePipelineSyncs: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const imagePipeline = store.selectActiveImagePipeline(state);

    dispatch({ type: 'FETCH_IMAGE_PIPELINE_SYNCS_START' });
    const response = await ImagePipelineSync.includes([
      'transitions',
      'image',
      'performed_by',
    ])
      .where({
        image_pipeline_id: imagePipeline.id,
      })
      .all();
    dispatch({
      type: 'FETCH_IMAGE_PIPELINE_SYNCS_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchActiveImagePipeline: imagePipelineId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_IMAGE_PIPELINE_START' });

    const response = await ImagePipeline.includes([
      { last_image_pipeline_sync: ['transitions', 'image', 'performed_by'] },
      { facility: ['client', 'region'] },
      'instance_type',
      'keypair',
      'availability_zone',
      'vpc',
      'subnet',
      { chef_package: { cookbooks: 'recipes' } },
      { image_definition: 'created_by' },
      'source_image_pipeline',
      'downstream_image_pipelines',
    ]).find(imagePipelineId);
    dispatch({
      type: 'FETCH_IMAGE_PIPELINE_SUCCESS',
      payload: { result: response.data },
    });
  },

  doRefreshImagePipeline: () => async ({ store, getState }) => {
    const state = getState();
    const imagePipeline = store.selectActiveImagePipeline(state).dup();

    store.doFetchActiveImagePipeline(imagePipeline.id);
  },

  doChangeImagePipelineSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_IMAGE_PIPELINE_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchImagePipelines();
  },

  doToggleImagePipelineSelection: imagePipeline => ({ dispatch }) => {
    dispatch({
      type: 'TOGGLE_IMAGE_PIPELINE_SELECTION',
      payload: { imagePipeline },
    });
  },

  doCreateImagePipeline: values => async ({ dispatch, store, getState }) => {
    const state = getState();

    const activeFacility = store.selectActiveFacility(state);
    const activeClient = store.selectActiveClient(state);
    const imagePipeline = new ImagePipeline({
      facility: activeFacility,
      client: activeClient,
      ...values,
    });

    const success = await imagePipeline.save({
      with: [
        'facility.id',
        'chefPackage.id',
        'client.id',
        'keypair.id',
        'vpc.id',
        'availabilityZone.id',
        'instanceType.id',
        'subnet.id',
        'sourceImagePipeline.id',
      ],
    });
    if (success) {
      dispatch({ type: 'ADD_IMAGE_PIPELINE', payload: { imagePipeline } });
    }
    return imagePipeline;
  },

  doDestroyImagePipeline: imagePipeline => async ({ dispatch }) => {
    await imagePipeline.destroy();
    dispatch({ type: 'REMOVE_IMAGE_PIPELINE', payload: { imagePipeline } });
  },

  doUpdateImagePipeline: values => async ({ dispatch, store, getState }) => {
    const state = getState();

    const imagePipeline = store.selectActiveImagePipeline(state).dup();

    if (
      values.sourceImagePipeline === null &&
      imagePipeline.sourceImagePipeline
    ) {
      imagePipeline.sourceImagePipeline.isMarkedForDisassociation = true;
      delete values.sourceImagePipeline;
    }
    imagePipeline.assignAttributes(values);

    const success = await imagePipeline.save({
      with: [
        'chefPackage.id',
        'keypair.id',
        'vpc.id',
        'availabilityZone.id',
        'instanceType.id',
        'subnet.id',
        'sourceImagePipeline',
      ],
    });
    if (success) {
      dispatch({
        type: 'UPDATE_IMAGE_PIPELINE',
        payload: { imagePipeline },
      });
    }
    return imagePipeline;
  },

  doSyncImagePipeline: () => async ({ getState, store }) => {
    const state = getState();
    const imagePipeline = store.selectActiveImagePipeline(state).dup();

    const imagePipelineSync = new ImagePipelineSync({ imagePipeline });

    await imagePipelineSync.save({ with: ['imagePipeline.id'] });
    if (imagePipelineSync.isPersisted) {
      store.doFetchActiveImagePipeline(imagePipeline.id);
    }
  },

  doCancelImagePipelineSync: imagePipelineSync => async ({
    getState,
    store,
  }) => {
    const state = getState();

    const imagePipelineSyncCancellation = new ImagePipelineSyncCancellation({
      imagePipelineSync,
    });

    await imagePipelineSyncCancellation.save({
      with: ['imagePipelineSync.id'],
    });
  },

  doCreateImageDefinition: values => async ({ dispatch, getState, store }) => {
    const state = getState();
    const imagePipeline = store.selectActiveImagePipeline(state).dup();

    const imageDefinition = new ImageDefinition({
      runList: values.runList,
      imagePipeline: imagePipeline,
    });
    await imageDefinition.save({ with: ['imagePipeline.id'] });
    store.doRefreshImagePipeline();
  },

  selectImagePipelineSortBy: state => state.imagePipelines.sortBy,
  selectImagePipelineSortDirection: state => state.imagePipelines.sortDirection,
  selectImagePipelineLoading: state => state.imagePipelines.loading,
  selectImagePipelineRaw: state => state.imagePipelines,
  selectImagePipelineData: state => state.imagePipelines.data || [],
  selectImagePipelineSyncs: state => state.imagePipelines.syncData || [],
  selectSelectedImagePipelineCount: createSelector(
    'selectImagePipelineData',
    imagePipelineData => imagePipelineData.filter(u => u.selected).length,
  ),
  selectSelectedImagePipelines: createSelector(
    'selectImagePipelineData',
    imagePipelineData => imagePipelineData.filter(u => u.selected),
  ),

  selectActiveImagePipelineId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.imagePipelineId,
  ),
  selectActiveImagePipeline: createSelector(
    'selectImagePipelineRaw',
    imagePipelineData => imagePipelineData.activeImagePipeline,
  ),
  reactShouldFetchImagePipelines: createSelector(
    'selectRouteApis',
    'selectImagePipelineRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, imagePipelineData, currentUser, activeClient) => {
      const wantsImagePipelines = apis.includes('imagePipelines');
      if (
        !wantsImagePipelines ||
        imagePipelineData.loading ||
        imagePipelineData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchImagePipelines' };
    },
  ),
  reactShouldFetchImagePipelineSyncs: createSelector(
    'selectRouteApis',
    'selectImagePipelineRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveImagePipeline',
    (
      apis,
      imagePipelineData,
      currentUser,
      activeClient,
      activeImagePipeline,
    ) => {
      const wantsImagePipelineSyncs = apis.includes('imagePipelineSyncs');
      if (
        !activeImagePipeline ||
        !wantsImagePipelineSyncs ||
        imagePipelineData.loadingSyncs ||
        imagePipelineData.syncData ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchImagePipelineSyncs' };
    },
  ),
  reactShouldFetchActiveImagePipeline: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveImagePipeline',
    'selectImagePipelineRaw',
    (routeParams, pathname, activeImagePipeline, imagePipelineData) => {
      if (
        !pathname.includes('/image_pipelines') ||
        !routeParams.imagePipelineId ||
        imagePipelineData.loadingActiveImagePipeline
      ) {
        return null;
      }
      if (
        activeImagePipeline &&
        activeImagePipeline.id === routeParams.imagePipelineId
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchActiveImagePipeline',
        args: [routeParams.imagePipelineId],
      };
    },
  ),

  reactShouldResetImagePipeline: createSelector(
    'selectActiveImagePipelineId',
    'selectActiveImagePipeline',
    (activeImagePipelineId, activeImagePipeline) => {
      if (!activeImagePipeline) return null;
      if (activeImagePipeline.id !== activeImagePipelineId) {
        return { actionCreator: 'doResetImagePipeline' };
      }

      return null;
    },
  ),
};
