import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class Directory extends ApplicationRecord {
  static jsonapiType = 'directories';
  @Attr() domain: string;
  @Attr() netbios: string;
  @Attr() realm: string;
  @Attr() computersOu: string;
  @Attr() usersOu: string;

  @BelongsTo() client: Client;
}
