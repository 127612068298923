import { createSelector } from 'redux-bundler';

export default {
  name: 'utils',
  selectRouteApis: createSelector(
    'selectRoute',
    route => {
      const { options = {} } = route;
      return options.apis || [];
    },
  ),
  selectBaseUrl: createSelector(
    'selectActiveClient',
    'selectActiveFacility',
    (activeClient, activeFacility) => {
      if (activeClient && activeFacility) {
        return `/clients/${activeClient.id}/facilities/${activeFacility.id}`;
      }

      if (activeClient) {
        return `/clients/${activeClient.id}`;
      }

      return null;
    },
  ),
};
