import { createSelector } from 'redux-bundler';

import Keypair from '../models/keypair';

export default {
  name: 'keypairs',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_KEYPAIRS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_KEYPAIRS_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }

      return state;
    };
  },

  doFetchKeypairs: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    dispatch({ type: 'FETCH_KEYPAIRS' });
    const scope = Keypair.where({
      facility_id: facility.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_KEYPAIRS_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchKeypairs: createSelector(
    'selectRouteApis',
    'selectKeypairsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    (apis, keypairsRaw, currentUser, activeClient, activeFacility) => {
      const wantsKeypairs = apis.includes('keypairs');
      if (
        !wantsKeypairs ||
        keypairsRaw.loading ||
        keypairsRaw.items ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }
      return { actionCreator: 'doFetchKeypairs' };
    },
  ),

  selectKeypairsRaw: state => state.keypairs,
  selectKeypairs: state => state.keypairs.items,
};
