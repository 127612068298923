import { Auth } from 'aws-amplify';
import React from 'react';
import PropTypes from 'prop-types';
import PusherContext from './PusherContext';

export default function PusherSubscriptionWrapper({
  channelName,
  eventName,
  onUpdate,
}) {
  return (
    <PusherContext.Consumer>
      {pusher =>
        pusher && (
          <PusherSubscription
            pusher={pusher}
            channelName={channelName}
            eventName={eventName}
            onUpdate={onUpdate}
          />
        )
      }
    </PusherContext.Consumer>
  );
}

PusherSubscriptionWrapper.propTypes = {
  channelName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export class PusherSubscription extends React.Component {
  componentDidMount() {
    const { pusher } = this.props;
    if (pusher) {
      this.subscribeToChannel();
    }
  }

  componentDidUpdate(prevProps) {
    const { channelName: prevChannelName, pusher: prevPusher } =
      prevProps || {};
    const { channelName: newChannelName, pusher: newPusher } = this.props;

    if (newChannelName !== prevChannelName || prevPusher !== newPusher) {
      console.log('Resubscribing');
      this.unsubscribe(prevChannelName);
      this.subscribeToChannel();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribeToChannel = async () => {
    const { pusher, channelName, eventName, onUpdate } = this.props;

    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    pusher.config.auth.headers.Authorization = token;

    this.channel = pusher.subscribe(channelName);
    this.channel.bind(eventName, payload => {
      onUpdate(payload);
    });
  };

  unsubscribe = oldChannelName => {
    const { pusher, channelName } = this.props;
    pusher.unsubscribe(oldChannelName || channelName);
  };

  render() {
    return null;
  }
}

PusherSubscriptionWrapper.propTypes = {
  channelName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

PusherSubscription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pusher: PropTypes.object.isRequired,
  channelName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
