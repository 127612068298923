import { Model, Attr, HasMany } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';
import ClientUserAssignment from './client_user_assignment';
import Facility from './facility';
import User from './user';

import faker from 'faker/locale/en';

@Model()
export default class Client extends ApplicationRecord {
  static jsonapiType = 'clients';
  @Attr() name: string;
  @Attr() internalName: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() userCount: number;
  @Attr() facilityCount: number;
  @Attr() onlineWorkstationCount: number;
  @Attr() totalWorkstationCount: number;

  @Attr() onlineRenderCount: number;
  @Attr() totalRenderCount: number;

  @HasMany() users: User[];
  @HasMany() accounts: Account[];
  @HasMany() facilities: Facility[];

  @HasMany() clientUserAssignments: ClientUserAssignment[];

  constructor(attrs?: Record<string, any>) {
    const defaults = { selected: false };
    super({ ...defaults, ...attrs });
  }

  clientNameOrFake(fake: Boolean): string {
    if (fake && this.internalName) {
      return this.internalName;
    }

    if (fake) {
      Client.seedFaker(this.name);
      return (
        faker.company
          .companyName()
          .split(' ')[0]
          .replace(/,/, '') + ' VFX'
      );
    }
    return this.name;
  }

  static seedFaker(seed: String) {
    const intSeed = seed
      .split('')
      .reverse()
      .map(c => c.charCodeAt(0))
      .reduce((a, b) => a + b, 0);

    faker.seed(intSeed);
  }
}
