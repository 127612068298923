import { composeBundles } from 'redux-bundler';

import accounts from './accounts';
import auth from './auth';
import availabilityZones from './availability_zones';
import chefPackages from './chef_packages';
import clients from './clients';
import dashboardStats from './dashboard_stats';
import directories from './directories';
import extraArgs from './extra-args';
import facilities from './facilities';
import groups from './groups';
import imagePipelines from './image_pipelines';
import images from './images';
import instanceTypes from './instance_types';
import keypairs from './keypairs';
import licenses from './licenses';
import masquerades from './masquerades';
import otpCodes from './otp_codes';
import passwordChanges from './password_changes';
import preferences from './preferences';
import pusher from './pusher';
import regions from './regions';
import roles from './roles';
import routes from './routes';
import securityGroups from './security_groups';
import subnets from './subnets';
import teams from './teams';
import templates from './templates';
import users from './users';
import utils from './utils';
import vpcs from './vpcs';
import weka from './weka';
import wekaConfigurationUploads from './weka_configuration_uploads';
import workstationCreationGroups from './workstation_creation_groups';
import workstationEventFeedEntries from './workstation_event_feed_entries';
import workstationStats from './workstation_stats';
import workstations from './workstations';

// import cache from '../utils/cache'

export default composeBundles(
  availabilityZones,
  accounts,
  auth,
  chefPackages,
  clients,
  dashboardStats,
  directories,
  extraArgs,
  facilities,
  groups,
  imagePipelines,
  images,
  instanceTypes,
  keypairs,
  licenses,
  masquerades,
  otpCodes,
  passwordChanges,
  preferences,
  pusher,
  regions,
  roles,
  routes,
  securityGroups,
  subnets,
  teams,
  templates,
  users,
  utils,
  vpcs,
  weka,
  wekaConfigurationUploads,
  workstationCreationGroups,
  workstationEventFeedEntries,
  workstations,
  workstationStats,
);
