import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Role from '../models/role';
import RolePermissionAssignment from '../models/role_permission_assignment';

export default {
  name: 'roles',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeRole: null,
      loadingActiveRole: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_ROLE_START') {
        return { ...state, loadingActiveRole: true };
      }
      if (type === 'FETCH_ROLE_SUCCESS') {
        return {
          ...state,
          loadingActiveRole: false,
          activeRole: payload.result,
        };
      }
      if (type === 'FETCH_ROLES_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_ROLES_SUCCESS') {
        return { ...state, loading: false, data: payload.results };
      }
      if (type === 'ADD_ROLE') {
        return { ...state, data: state.data.concat([payload.role]) };
      }
      if (type === 'REMOVE_ROLE') {
        if (state.data) {
          const index = state.data.findIndex(u => u.id === payload.role.id);
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat(state.data.slice(index + 1)),
          };
        }

        return state;
      }

      if (type === 'CHANGE_ROLE_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }

      return state;
    };
  },

  doChangeRoleSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({ type: 'CHANGE_ROLE_SORT', payload: { sortDirection, sortBy } });
    store.doFetchRoles();
  },

  doFetchRoles: () => async ({ dispatch, apiFetch, getState }) => {
    const state = getState();
    const { sortBy } = state.roles;
    const { sortDirection } = state.roles;

    dispatch({ type: 'FETCH_ROLES_START' });
    const response = await Role.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    }).all();
    dispatch({
      type: 'FETCH_ROLES_SUCCESS',
      payload: { results: response.data },
    });
  },

  doFetchActiveRole: roleId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_ROLE_START' });

    const response = await Role.includes([
      'users',
      'permissions',
      { role_permission_assignments: 'permission' },
    ])
      .order('permissions.name')
      .find(roleId);
    dispatch({
      type: 'FETCH_ROLE_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateRole: values => async ({ dispatch }) => {
    const role = new Role({
      name: values.name,
    });

    const success = await role.save({});
    if (success) {
      dispatch({ type: 'ADD_ROLE', payload: { role } });
    }
    return role;
  },
  doDestroyRole: role => async ({ dispatch }) => {
    await role.destroy();
    dispatch({ type: 'REMOVE_ROLE', payload: { role } });
  },

  doUpdateRole: values => async ({ dispatch, store }) => {
    const {
      roles: { activeRole },
    } = store.getState();

    const role = activeRole.dup();
    role.assignAttributes(values);

    const success = await role.save();
    if (success) {
      dispatch({
        type: 'UPDATE_ROLE',
        payload: { role },
      });
    }
    return role;
  },

  doRemovePermissionFromRole: (permission, role) => async ({
    dispatch,
    store,
    getState,
  }) => {
    const nextRole = role.dup();
    nextRole.rolePermissionAssignments.forEach(rpa => {
      if (rpa.permission.id === permission.id) {
        rpa.isMarkedForDestruction = true;
      }
    });

    const success = await nextRole.save({
      with: [{ rolePermissionAssignments: 'permission.id' }, 'permissions'],
    });

    const response = await Role.includes([
      'permissions',
      { role_permission_assignments: 'permission' },
    ]).find(nextRole.id);
    const reloadedRole = response.data;

    if (success) {
      dispatch({
        type: 'UPDATE_ROLE',
        payload: { user: reloadedRole },
      });

      const state = getState();
      const activeRole = store.selectActiveRole(state);
      if (activeRole) {
        store.doFetchActiveRole(activeRole.id);
      }
    }
    return reloadedRole;
  },

  doAddPermissionsToRole: permissions => async ({ store, getState }) => {
    const state = getState();
    const activeRole = store.selectActiveRole(state);
    const nextRole = activeRole.dup();

    const rolePermissionAssignments = permissions.map(
      permission =>
        new RolePermissionAssignment({ permissionName: permission }),
    );

    nextRole.rolePermissionAssignments = [
      ...nextRole.rolePermissionAssignments,
      ...rolePermissionAssignments,
    ];

    await nextRole.save({
      with: ['rolePermissionAssignments'],
    });

    store.doFetchActiveRole(activeRole.id);
  },

  selectActiveRole: createSelector(
    'selectRolesState',
    rolesState => rolesState.activeRole,
  ),

  selectRolesSortBy: state => state.roles.sortBy,
  selectRolesSortDirection: state => state.roles.sortDirection,
  selectRolesLoading: state => state.roles.loading,
  selectRolesState: state => state.roles,
  selectRolesData: state => state.roles.data || [],
  reactShouldFetchRoles: createSelector(
    'selectRouteApis',
    'selectRolesState',
    'selectCurrentUser',
    (apis, rolesState, currentUser) => {
      const wantsRoles = apis.includes('roles');
      if (
        !wantsRoles ||
        rolesState.loading ||
        rolesState.data ||
        !currentUser
      ) {
        return false;
      }
      return { actionCreator: 'doFetchRoles' };
    },
  ),

  reactShouldFetchActiveRole: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveRole',
    'selectRolesState',
    (routeParams, pathname, activeRole, roleData) => {
      if (
        !pathname.includes('/roles') ||
        !routeParams.roleId ||
        roleData.loadingActiveRole
      ) {
        return null;
      }
      if (activeRole && activeRole.id === routeParams.roleId) {
        return null;
      }

      return { actionCreator: 'doFetchActiveRole', args: [routeParams.roleId] };
    },
  ),
};
