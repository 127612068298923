import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  icon: {
    margin: theme.spacing(1),
  },
});

function InfoTooltip({ children, classes }) {
  return (
    <Tooltip title={children} interactive>
      <InfoIcon className={classes.icon} color="disabled" fontSize="small" />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(InfoTooltip);
