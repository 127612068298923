import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import License from '../models/license';
import App from '../models/app';

export default {
  name: 'licenses',
  getReducer: () => {
    const initialData = {
      data: null,
      syncData: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      loadingSyncs: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_LICENSES_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_LICENSES_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
        };
      }

      if (type === 'CHANGE_LICENSES_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'UPDATE_LICENSE') {
        const { license } = payload;
        const nextData =
          state.data &&
          state.data.map(l => (l.id === license.id ? license : w));

        return {
          ...state,
          data: nextData,
        };
      }
      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doFetchLicenses: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.licenses;
    const { sortDirection } = state.licenses;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_LICENSES_START' });
    const response = await License.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .includes(['app'])

      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      })
      .where({
        client_id: client.id,
      })
      .all();
    dispatch({
      type: 'FETCH_LICENSES_SUCCESS',
      payload: { results: response.data },
    });
  },

  doChangeLicensesSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_LICENSES_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchLicenses();
  },

  doUpdateLicenseFromPusher: payload => ({ dispatch, getState, store }) => {
    const license = License.fromJsonapi(payload.data, payload);
    const state = getState();

    const oldLicense = find(
      store.selectLicenseData(getState()),
      w => w.id === license.id,
    );

    if (oldLicense) {
      dispatch({
        type: 'UPDATE_LICENSE',
        payload: { license },
      });
    } else {
      store.doFetchLicenses();
      // dispatch({
      //   type: 'ADD_WORKSTATION',
      //   payload: { workstation },
      // });
    }
  },

  selectLicensesSortBy: state => state.licenses.sortBy,
  selectLicensesSortDirection: state => state.licenses.sortDirection,
  selectLicensesLoading: state => state.licenses.loading,
  selectLicenseRaw: state => state.licenses,
  selectLicenseData: state => state.licenses.data || [],
  selectLicenseSyncs: state => state.licenses.syncData || [],
  selectSelectedLicenseCount: createSelector(
    'selectLicenseData',
    licenseData => licenseData.filter(u => u.selected).length,
  ),

  selectLicensesUsedByWorkstation: createSelector(
    'selectLicenseData',
    'selectActiveWorkstation',
    (licenseData, activeWorkstation) => {
      return licenseData.filter(l => l.isInUseByWorkstation(activeWorkstation));
    },
  ),
  selectSelectedLicenses: createSelector('selectLicenseData', licenseData =>
    licenseData.filter(u => u.selected),
  ),

  reactShouldFetchLicenses: createSelector(
    'selectRouteApis',
    'selectLicenseRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, licenseData, currentUser, activeClient) => {
      const wantsLicenses = apis.includes('licenses');
      if (
        !wantsLicenses ||
        licenseData.loading ||
        licenseData.data ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchLicenses' };
    },
  ),
};
