import { createSelector } from 'redux-bundler';
import moment from 'moment-timezone';
import { every } from 'lodash';

import Stat from '../models/stat';
import WorkstationStatRetrieval from '../models/workstation_stat_retrieval';

const STALE_AFTER = 300 * 1000;

export default {
  name: 'workstation_stats',
  getReducer: () => {
    const initialData = {
      loading: false,
      stats: {},
      timezone: moment.tz.guess(),
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_START') {
        return { ...state, loading: true, error: false };
      }
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_SUCCESS') {
        return { ...state, loading: false, error: false };
      }
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_FAILURE') {
        return { ...state, loading: false, error: true, lastError: Date.now() };
      }
      if (type === 'SET_TIMEZONE') {
        return { ...state, timezone: payload.timezone };
      }
      if (type === 'FETCH_WORKSTATION_STATS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_WORKSTATION_STATS_SUCCESS') {
        return {
          ...state,
          loading: false,
          stats: {
            [payload.workstationId]: {
              loaded: true,
              data: payload.result,
              time: Date.now(),
            },
          },
        };
      }

      return state;
    };
  },

  doSetTimezone: timezone => ({ dispatch }) => {
    dispatch({
      type: 'SET_TIMEZONE',
      payload: { timezone },
    });
  },

  doFetchWorkstationStats: workstationId => async ({
    dispatch,
    store,
    getState,
  }) => {
    dispatch({ type: 'FETCH_WORKSTATION_STATS_START' });

    const response = await Stat.where({
      statable_id: workstationId,
      statable_type: 'Workstation',
    }).all();
    if (response.data.length === 0) {
      console.log('No stats');
      //store.doCreateWorkstationStatRetrieval();
      return;
    }

    if (every(response.data, datum => datum.isStale())) {
      console.log('No stats 2');
      store.doCreateWorkstationStatRetrieval();
      return;
    }

    dispatch({
      type: 'FETCH_WORKSTATION_STATS_SUCCESS',
      payload: { workstationId, result: response.data },
    });
  },

  doCreateWorkstationStatRetrieval: () => async ({
    dispatch,
    store,
    getState,
  }) => {
    try {
      if (!store.selectShowMetrics(getState())) {
        return false;
      }
      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_START' });
      const state = getState();
      const facility = store.selectActiveFacility(state).dup();
      const workstation = store.selectActiveWorkstation(state)?.dup();

      const workstationStatRetrieval = new WorkstationStatRetrieval({
        facility,
        workstation,
      });

      await workstationStatRetrieval.save({
        with: ['facility.id', 'workstation.id'],
      });
      if (workstation) {
        await store.doFetchWorkstationStats(workstation.id);
      }
      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_FAILURE' });
      console.log(err);
    }
  },

  selectWorkstationStatsTimezone: state => state.workstation_stats.timezone,
  selectWorkstationStatsState: state => state.workstation_stats,
  selectWorkstationStats: createSelector(
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    (activeWorkstation, workstationStatState) => {
      if (!activeWorkstation) return [];

      return workstationStatState.stats[activeWorkstation.id]?.data || [];
    },
  ),

  selectWorkstationStatsLoaded: createSelector(
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    (activeWorkstation, workstationStatState) => {
      if (!activeWorkstation) return false;

      return workstationStatState.stats[activeWorkstation.id]?.loaded || false;
    },
  ),

  selectWorkstationStatsStale: createSelector(
    'selectAppTime',
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    'selectWorkstationStats',
    (appTime, activeWorkstation, workstationStatState, workstationStats) => {
      if (!activeWorkstation) return false;

      if (!workstationStats[0]) {
        return true;
      }

      return every(workstationStats, datum => datum.isStale());
    },
  ),

  selectLoadingWorkstationStats: createSelector(
    'selectWorkstationStatsState',
    workstationStatState => workstationStatState.loading,
  ),

  reactShouldFetchWorkstationStats: createSelector(
    'selectRouteApis',
    'selectRouteParams',
    'selectPathname',
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    'selectWorkstationStats',
    'selectWorkstationStatsLoaded',
    'selectWorkstationStatsStale',
    'selectShowMetrics',
    'selectAuthenticatedUser',
    (
      apis,
      routeParams,
      pathname,
      activeWorkstation,
      workstationStatState,
      stats,
      loaded,
      stale,
      showMetrics,
      authenticatedUser,
    ) => {
      const wantsWorkstationStats = apis.includes('workstationStats');
      if (!showMetrics) return null;
      if (!wantsWorkstationStats) return null;
      if (!activeWorkstation) return null;
      if (!routeParams.workstationId) return null;
      if (workstationStatState.loading) return null;
      if (!authenticatedUser) return null;
      if (!activeWorkstation.instanceId) return null;
      if (!authenticatedUser.isAuthorized('ui.workstation.stats.view'))
        return null;
      if (loaded && !stale) {
        return null;
      }

      return {
        actionCreator: 'doFetchWorkstationStats',
        args: [routeParams.workstationId],
      };
    },
  ),
};
