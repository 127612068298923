import { Auth } from 'aws-amplify';
import { createSelector } from 'redux-bundler';
import { MiddlewareStack } from 'spraypaint';
import { ApplicationRecord } from '../models/application_record';
import LogRocket from 'logrocket';

const middleware = new MiddlewareStack();
middleware.beforeFilters.push(async (url, options) => {
  try {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    options.headers.Authorization = `Token ${token}`;
  } catch (err) {
    console.log(err);

    try {
      Auth.signOut();
    } catch (err2) {
      window.location.reload();
      console.log(err2);
    }
  }
});

middleware.afterFilters.push((response, json) => {
  if (response.status === 401) {
    console.log('User is signed out');
    Auth.signOut();
    throw 'abort';
  }
});

ApplicationRecord.middlewareStack = middleware;

export default {
  name: 'auth',
  getReducer: () => {
    const initialData = {
      user: null,
      lastRefresh: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_CURRENT_USER') {
        return {
          ...state,
          ...{
            user: payload.user,
            session: payload.user && payload.user.signInUserSession,
            lastRefresh: Date.now(),
          },
        };
      }

      if (type === 'SET_CURRENT_SESSION') {
        return {
          ...state,
          ...{
            session: payload.session,
            lastRefresh: Date.now(),
          },
        };
      }

      return state;
    };
  },
  doSetCurrentUser: user => ({ dispatch }) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      payload: {
        user,
      },
    });

    LogRocket.identify(user?.attributes?.email);
  },
  doFetchUser: () => async ({ dispatch, store, getState }) => {
    try {
      const session = await Auth.currentSession();

      dispatch({
        type: 'SET_CURRENT_SESSION',
        payload: {
          session,
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
  selectCurrentUser: state => state.auth.user,
  selectCurrentSession: state => state.auth.session,
  selectJwtToken: createSelector(
    'selectCurrentSession',
    session => session && session.idToken.jwtToken,
  ),
  selectLastRefresh: state => state.auth.lastRefresh,
};
