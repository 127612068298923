import { Model, Attr } from 'spraypaint';

import Account from './account';

@Model()
export default class AwsAccount extends Account {
  static jsonapiType = 'aws_accounts';
  @Attr() roleArn: string;
  @Attr({ persist: false }) selected: boolean;
}
