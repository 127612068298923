import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import User from './user';

@Model()
export default class ClientUserAssignment extends ApplicationRecord {
  static jsonapiType = 'client_user_assignments';

  @Attr() clientId: string;
  @Attr() username: string;
  @Attr() distinguishedName: string;
  @Attr() password: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() localAdmin: boolean;
  @Attr() recentHours: number;
  @Attr() totalHours: number;
  @Attr() mtdHours: number;

  @BelongsTo() client: Client;
  @BelongsTo() user: User;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  usernameOrFake(fake: Boolean, user: User) {
    if (fake) {
      return `${user
        .fakeFirstName()
        .slice(0, 1)}${user.fakeLastName()}`.toLowerCase();
    }
    return this.username;
  }

  distinguishedNameOrFake(fake: Boolean, user: User) {
    if (fake) {
      return `cn=${user.fakeFirstName()} ${user.fakeLastName()},ou=Users,dc=Company,dc=Local`;
    }
    return this.distinguishedName;
  }
}
