import { createSelector } from 'redux-bundler';
import { find, snakeCase, xor } from 'lodash';
import pako from 'pako';

import Workstation from '../models/workstation';
import WorkstationCreationCheck from '../models/workstation_creation_check';
import WorkstationDeletion from '../models/workstation_deletion';
import WorkstationBoot from '../models/workstation_boot';
import WorkstationReboot from '../models/workstation_reboot';
import WorkstationShutdown from '../models/workstation_shutdown';
import WorkstationNamingSuggestion from '../models/workstation_naming_suggestion';

// eslint-disable-next-line no-unused-vars

import WorkstationLogin from '../models/workstation_login';
import WorkstationConnection from '../models/workstation_connection';
import WorkstationCreation from '../models/workstation_creation';
import WorkstationDeletionCheck from '../models/workstation_deletion_check';
import WorkstationGroupAssignment from '../models/workstation_group_assignment';
import WorkstationManeAgentUpdate from '../models/workstation_mane_agent_update';
import WorkstationMountsUpdate from '../models/workstation_mounts_update';

const STALE_AFTER = 30000;
const AW_STALE_AFTER = 300 * 1000;

const DEFAULT_WORKSTATION_COLUMNS = [
  'name',
  'template',
  'instanceType',
  'instanceState',
  'zone',
  'lastUser',
];

export const ALL_WORKSTATION_COLUMNS = {
  name: 'Name',
  template: 'Template',
  instanceType: 'Instance Type',
  instanceState: 'Instance State',
  zone: 'Zone',
  lastUser: 'Last User',
  lastLoginTime: 'Last Login Time',
  createdAt: 'Creation Time',
  totalHours: 'Total Hours',
  recentHours: '1M Hours',
  mtdHours: 'MTD Hours',
  usageGraph: 'Usage Graph',
};

export default {
  name: 'workstations',
  getReducer: () => {
    const initialData = {
      render: false,
      activeWorkstation: null,
      activeWorkstationLastFetched: null,
      data: null,
      sortDirection: 'ASC',
      sortBy: 'instanceState',
      loading: false,
      loaded: false,
      gridView: false,
      filteredFacilityId: null,
      filteredClientId: null,
      lastSuccess: null,
      filterValues: {},
      columns: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_WORKSTATIONS_START') {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      if (type === 'FETCH_WORKSTATIONS_SUCCESS') {
        return {
          ...state,
          loading: false,
          loaded: true,
          data: payload.result,
          lastSuccess: payload.time,
          render: payload.render,
        };
      }
      if (type === 'FETCH_WORKSTATION_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_WORKSTATION_SUCCESS') {
        return {
          ...state,
          loading: false,
          activeWorkstationLastFetched: Date.now(),
          activeWorkstation: payload.result,
        };
      }
      if (type === 'ADD_WORKSTATION') {
        const { workstation } = payload;

        if (!state.data) {
          return state;
        }

        return {
          ...state,
          data: [...state.data, workstation],
        };
      }
      if (type === 'UPDATE_ACTIVE_WORKSTATION') {
        const { workstation } = payload;
        return {
          ...state,
          activeWorkstation: workstation,
        };
      }
      if (type === 'UPDATE_WORKSTATION') {
        const { workstation } = payload;
        const nextData =
          state.data &&
          state.data.map(w => (w.id === workstation.id ? workstation : w));

        return {
          ...state,
          data: nextData,
        };
      }
      if (type === 'REMOVE_WORKSTATION') {
        return {
          ...state,
          activeWorkstation: null,
        };
      }
      if (type === 'CHANGE_WORKSTATION_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'TOGGLE_WORKSTATION_SELECTION') {
        const { workstation } = payload;
        const nextWorkstation = workstation.dup();
        nextWorkstation.selected = !nextWorkstation.selected;

        return {
          ...state,
          data: state.data.map(w =>
            w.id === nextWorkstation.id ? nextWorkstation : w,
          ),
        };
      }

      if (type === 'SELECT_ALL_WORKSTATIONS') {
        if (!state.data) {
          return state;
        }
        return {
          ...state,
          data: state.data.map(w => {
            const nextWorkstation = w.dup();
            nextWorkstation.selected = true;
            return nextWorkstation;
          }),
        };
      }
      if (type === 'DESELECT_ALL_WORKSTATIONS') {
        if (!state.data) {
          return state;
        }
        return {
          ...state,
          data: state.data.map(w => {
            const nextWorkstation = w.dup();
            nextWorkstation.selected = false;
            return nextWorkstation;
          }),
        };
      }
      if (type === 'TOGGLE_GRID_VIEW') {
        return {
          ...state,
          gridView: !state.gridView,
        };
      }

      if (type === 'RESET_ACTIVE_WORKSTATION') {
        return {
          ...state,
          activeWorkstation: null,
          activeWorkstationLastFetched: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_WORKSTATIONS') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'FILTER_WORKSTATIONS_BY_GROUP') {
        return {
          ...state,
          filters: {
            ...state.filters,
            group_id: payload.map(g => g.id),
          },
          filterValues: {
            ...state.filterValues,
            groups: payload,
          },
        };
      }

      if (type === 'FILTER_WORKSTATIONS_BY_INSTANCE_STATE') {
        return {
          ...state,
          filters: {
            ...state.filters,
            instance_state: payload,
          },
          filterValues: {
            ...state.filterValues,
            instance_state: payload,
          },
        };
      }

      if (type === 'FILTER_WORKSTATIONS_BY_TEMPLATE') {
        return {
          ...state,
          filters: {
            ...state.filters,
            template_id: payload.map(t => t.id),
          },
          filterValues: {
            ...state.filterValues,
            templates: payload,
          },
        };
      }

      if (type === 'FILTER_WORKSTATIONS_BY_TYPE') {
        return {
          ...state,
          filters: {
            ...state.filters,
            instance_type_id: payload.map(t => t.id),
          },
          filterValues: {
            ...state.filterValues,
            types: payload,
          },
        };
      }

      if (type === 'FILTER_WORKSTATIONS_BY_TEAM') {
        return {
          ...state,
          filters: {
            ...state.filters,
            team_id: payload.map(t => t.id),
          },
          filterValues: {
            ...state.filterValues,
            teams: payload,
          },
        };
      }

      if (type === 'SET_WORKSTATION_COLUMNS') {
        return {
          ...state,
          columns: payload.columns,
        };
      }

      if (type === 'TOGGLE_WORKSTATION_COLUMN') {
        const { column } = payload;

        return {
          ...state,
          columns: xor(state.columns, [column]),
        };
      }

      if (type === 'RESET_WORKSTATION_COLUMNS') {
        return {
          ...state,
          columns: DEFAULT_WORKSTATION_COLUMNS,
        };
      }

      return state;
    };
  },

  doFilterWorkstationByGroup: groups => ({ dispatch, store }) => {
    dispatch({ type: 'FILTER_WORKSTATIONS_BY_GROUP', payload: groups });

    store.doFetchWorkstations();
  },

  doFilterWorkstationByInstanceState: instanceStates => ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'FILTER_WORKSTATIONS_BY_INSTANCE_STATE',
      payload: instanceStates,
    });

    store.doFetchWorkstations();
  },

  doFilterWorkstationByTemplate: templates => ({ dispatch, store }) => {
    dispatch({
      type: 'FILTER_WORKSTATIONS_BY_TEMPLATE',
      payload: templates,
    });

    store.doFetchWorkstations();
  },

  doFilterWorkstationByType: types => ({ dispatch, store }) => {
    dispatch({
      type: 'FILTER_WORKSTATIONS_BY_TYPE',
      payload: types,
    });

    store.doFetchWorkstations();
  },

  doFilterWorkstationByTeam: teams => ({ dispatch, store }) => {
    dispatch({
      type: 'FILTER_WORKSTATIONS_BY_TEAM',
      payload: teams,
    });

    store.doFetchWorkstations();
  },

  doResetActiveWorkstation: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_WORKSTATION' });
  },

  doResetWorkstations: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_WORKSTATIONS' });
  },

  doFetchWorkstations: reset => async ({ dispatch, getState, store }) => {
    dispatch({
      type: 'FETCH_WORKSTATIONS_START',
    });
    if (reset) {
      dispatch({
        type: 'RESET_WORKSTATIONS',
      });
    }
    const state = getState();
    const facility = store.selectActiveFacility(state);
    const selectedWorkstations = store.selectSelectedWorkstations(state);
    const routeApis = store.selectRouteApis(state);
    const render = routeApis.includes('render');

    const { sortBy, sortDirection } = getState().workstations;

    let relation = Workstation.where({ facility_id: facility.id })
      .where(state.workstations.filters)
      .where({ kept: true })
      .where({ render })
      .includes([
        'instance_type',
        'image',
        'template',
        'last_login',
        'last_connection',
        'workstation_hours_stat',
        ...(render ? ['cpu_stat'] : []),
      ])
      .order({ [snakeCase(sortBy)]: sortDirection.toLowerCase() });

    if (sortBy !== 'name') {
      relation = relation.order({ name: 'asc' });
    }

    const response = await relation.all();

    const data = response.data.map(w => {
      const nextWorkstation = w.dup();
      if (selectedWorkstations.find(cw => cw.id === w.id)) {
        nextWorkstation.selected = true;
      }
      return nextWorkstation;
    });

    dispatch({
      type: 'FETCH_WORKSTATIONS_SUCCESS',
      payload: {
        result: data,
        time: Date.now(),
        selected: selectedWorkstations,
        render,
      },
    });
  },

  doFetchWorkstation: workstationId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_WORKSTATION_START' });

    const response = await Workstation.includes([
      'instance_type',
      'image',
      'creation',
      'deletion',
      'pending_deletion',
      'groups',
      'last_login',
      'last_connection',
      'availability_zone',
    ])
      .where({ unscoped: true })
      .find(workstationId);
    dispatch({
      type: 'FETCH_WORKSTATION_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateWorkstationNamingSuggestion: (template, count) => async () => {
    const wns = new WorkstationNamingSuggestion({
      template,
      workstationCount: count,
    });
    await wns.save({ with: ['template.id'] });

    return wns;
  },

  doLoadWorkstationColumns: column => ({ dispatch, store, getState }) => {
    const columns = localStorage.getItem('workstationColumns');
    if (columns) {
      dispatch({
        type: 'SET_WORKSTATION_COLUMNS',
        payload: { columns: JSON.parse(columns) },
      });
    } else {
      dispatch({
        type: 'RESET_WORKSTATION_COLUMNS',
      });
    }
  },
  doToggleWorkstationColumn: column => ({ dispatch, store, getState }) => {
    dispatch({
      type: 'TOGGLE_WORKSTATION_COLUMN',
      payload: { column },
    });

    localStorage.setItem(
      'workstationColumns',
      JSON.stringify(store.selectWorkstationColumns()),
    );
  },

  doResetWorkstationColumns: () => ({ dispatch, store, getState }) => {
    dispatch({
      type: 'RESET_WORKSTATION_COLUMNS',
    });

    localStorage.setItem(
      'workstationColumns',
      JSON.stringify(store.selectWorkstationColumns()),
    );
  },

  doChangeWorkstationSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
    getState,
  }) => {
    dispatch({
      type: 'CHANGE_WORKSTATION_SORT',
      payload: { sortDirection, sortBy },
    });

    const state = getState();
    const { id } = store.selectActiveFacility(state);
    store.doFetchWorkstations(id);
  },

  doToggleWorkstationSelection: workstation => ({ dispatch }) => {
    dispatch({
      type: 'TOGGLE_WORKSTATION_SELECTION',
      payload: { workstation },
    });
  },

  doSelectAllWorkstations: () => ({ dispatch }) => {
    dispatch({
      type: 'SELECT_ALL_WORKSTATIONS',
    });
  },

  doDeselectAllWorkstations: () => ({ dispatch }) => {
    dispatch({
      type: 'DESELECT_ALL_WORKSTATIONS',
    });
  },

  doDestroyWorkstation: workstation => async ({ store }) => {
    const deletion = new WorkstationDeletion({
      workstation: workstation.dup(),
    });
    await deletion.save({ with: ['workstation.id'] });

    if (deletion.isPersisted) {
      store.doFetchWorkstation(workstation.id);
    }
  },

  doBootWorkstation: workstation => async ({ dispatch }) => {
    const boot = new WorkstationBoot({ workstation: workstation.dup() });
    await boot.save({ with: ['workstation.id'] });

    dispatch({
      type: 'UPDATE_WORKSTATION',
      payload: { workstation: boot.workstation },
    });
  },

  doRebootWorkstation: workstation => async ({ dispatch }) => {
    const reboot = new WorkstationReboot({ workstation: workstation.dup() });
    await reboot.save({ with: ['workstation.id'] });

    dispatch({
      type: 'UPDATE_WORKSTATION',
      payload: { workstation: reboot.workstation },
    });
  },

  doShutdownWorkstation: workstation => async ({ dispatch }) => {
    const shutdown = new WorkstationShutdown({
      workstation: workstation.dup(),
    });
    await shutdown.save({ with: ['workstation.id'] });

    dispatch({
      type: 'UPDATE_WORKSTATION',
      payload: { workstation: shutdown.workstation },
    });
  },

  doUpdateManeAgent: (workstation, maneAgentUrl) => async ({ dispatch }) => {
    const update = new WorkstationManeAgentUpdate({
      maneAgentUrl,
      workstation: workstation.dup(),
    });
    await update.save({ with: ['workstation.id'] });
  },

  doUpdateMounts: workstation => async ({ dispatch }) => {
    const update = new WorkstationMountsUpdate({
      workstation: workstation.dup(),
    });
    await update.save({ with: ['workstation.id'] });
  },

  doBulkUpdateManeAgent: maneAgentUrl => async ({
    dispatch,
    store,
    getState,
  }) => {
    const state = getState();
    const selectedWorkstations = store.selectSelectedWorkstations(state);
    for await (const workstation of selectedWorkstations) {
      const update = new WorkstationManeAgentUpdate({
        maneAgentUrl,
        workstation: workstation.dup(),
      });
      await update.save({ with: ['workstation.id'] });
    }
  },

  doCreateWorkstationDeletionCheck: workstation => async ({ store }) => {
    const workstationDeletion = workstation.dup().pendingDeletion;

    const check = new WorkstationDeletionCheck({ workstationDeletion });
    await check.save({ with: ['workstationDeletion.id'] });

    if (check.isPersisted) {
      store.doFetchWorkstation(workstation.id);
    }
  },

  doCreateWorkstationCreationCheck: workstation => async ({ store }) => {
    const workstationCreation = workstation.dup().creation;

    const check = new WorkstationCreationCheck({ workstationCreation });
    await check.save({ with: ['workstationCreation.id'] });

    if (check.isPersisted) {
      store.doFetchWorkstation(workstation.id);
    }
  },

  doUpdateWorkstationFromPusher: payload => ({ dispatch, getState, store }) => {
    const inflatedPayload = JSON.parse(
      pako.inflate(atob(payload), { to: 'string' }),
    );
    const workstation = Workstation.fromJsonapi(
      inflatedPayload.data,
      inflatedPayload,
    );
    const state = getState();

    const activeWorkstation = store.selectActiveWorkstation(state);
    if (activeWorkstation && activeWorkstation.id === workstation.id) {
      dispatch({
        type: 'UPDATE_ACTIVE_WORKSTATION',
        payload: { workstation },
      });
    }

    const oldWorkstation = find(
      store.selectWorkstations(getState()),
      w => w.id === workstation.id,
    );

    if (oldWorkstation) {
      workstation.selected = oldWorkstation.selected;

      dispatch({
        type: 'UPDATE_WORKSTATION',
        payload: { workstation },
      });
    } else {
      store.doFetchWorkstations();
      // dispatch({
      //   type: 'ADD_WORKSTATION',
      //   payload: { workstation },
      // });
    }
  },

  doToggleGridView: () => ({ dispatch }) =>
    dispatch({ type: 'TOGGLE_GRID_VIEW' }),

  doAddGroupsToWorkstation: (groups, workstation) => async ({
    store,
    getState,
  }) => {
    const nextWorkstation = workstation.dup();

    const workstationGroupAssignments = groups.map(
      group => new WorkstationGroupAssignment({ group }),
    );

    nextWorkstation.workstationGroupAssignments = [
      ...nextWorkstation.workstationGroupAssignments,
      ...workstationGroupAssignments,
    ];

    await nextWorkstation.save({
      with: [{ workstationGroupAssignments: 'group.id' }],
    });

    const state = getState();
    const activeWorkstation = store.selectActiveWorkstation(state);
    if (activeWorkstation) {
      store.doFetchWorkstation(activeWorkstation.id);
    }
  },

  selectWorkstationState: state => state.workstations,
  selectWorkstationFilterValues: state => state.workstations.filterValues,

  selectWorkstationColumns: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.columns || [],
  ),

  selectWorkstations: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.data || [],
  ),
  selectWorkstationLoading: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.loading,
  ),

  selectWorkstationLoaded: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.loaded,
  ),
  selectFilteredFacilityId: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.filteredFacilityId,
  ),
  selectFilteredClientId: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.filteredClientId,
  ),

  selectActiveWorkstationId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.workstationId,
  ),
  selectActiveWorkstation: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.activeWorkstation,
  ),
  selectWorkstationId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.workstationId,
  ),
  selectSelectedWorkstations: createSelector(
    'selectWorkstations',
    workstations => workstations && workstations.filter(w => w.selected),
  ),
  selectSelectedWorkstationsCount: createSelector(
    'selectSelectedWorkstations',
    selectedWorkstations => selectedWorkstations && selectedWorkstations.length,
  ),
  selectWorkstationSortBy: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.sortBy,
  ),
  selectWorkstationSortDirection: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.sortDirection,
  ),
  selectIsGridView: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.gridView,
  ),
  selectLastWorkstationSuccess: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.lastSuccess,
  ),

  selectActiveWorkstationLastFetched: createSelector(
    'selectWorkstationState',
    workstationState => workstationState.activeWorkstationLastFetched,
  ),
  selectShowRender: createSelector('selectRouteApis', apis =>
    apis.includes('render'),
  ),
  selectActiveWorkstationIsStale: createSelector(
    'selectActiveWorkstationLastFetched',
    'selectAppTime',
    (activeWorkstationLastFetched, appTime) => {
      if (!activeWorkstationLastFetched) {
        return true;
      }
      return appTime - activeWorkstationLastFetched > AW_STALE_AFTER;
    },
  ),
  selectWorkstationsAreStale: createSelector(
    'selectLastWorkstationSuccess',
    'selectAppTime',
    (lastSuccessTime, appTime) => {
      if (!lastSuccessTime) {
        return true;
      }
      return appTime - lastSuccessTime > STALE_AFTER;
    },
  ),
  reactShouldFetchWorkstations: createSelector(
    'selectRouteApis',
    'selectWorkstationState',
    'selectWorkstationLoading',
    'selectActiveFacility',
    'selectWorkstationsAreStale',
    (apis, workstationsState, loading, activeFacility, isStale) => {
      const { data: workstations } = workstationsState;
      const wantsWorkstations = apis.includes('workstations');

      const render = apis.includes('render');

      if (!wantsWorkstations) return null;
      if (loading) return null;

      if (!activeFacility) return null;

      if (render !== workstationsState.render) {
        return {
          actionCreator: 'doFetchWorkstations',
          args: [true],
        };
      }
      if (workstations && !isStale) return null;

      return {
        actionCreator: 'doFetchWorkstations',
        args: [false],
      };
    },
  ),
  reactShouldFetchActiveWorkstation: createSelector(
    'selectRouteApis',
    'selectRouteParams',
    'selectPathname',
    'selectActiveWorkstation',
    'selectWorkstationState',
    'selectActiveWorkstationIsStale',
    (
      apis,
      routeParams,
      pathname,
      activeWorkstation,
      workstationState,
      activeWorkstationIsStale,
    ) => {
      const wantsWorkstation = apis.includes('workstation');
      if (
        !wantsWorkstation ||
        !routeParams.workstationId ||
        workstationState.loading
      ) {
        return null;
      }
      if (
        activeWorkstation &&
        activeWorkstation.id === routeParams.workstationId &&
        !activeWorkstationIsStale
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchWorkstation',
        args: [routeParams.workstationId],
      };
    },
  ),

  reactShouldResetActiveWorkstation: createSelector(
    'selectActiveWorkstationId',
    'selectActiveWorkstation',
    (activeWorkstationId, activeWorkstation) => {
      if (!activeWorkstation) return null;
      if (activeWorkstation.id !== activeWorkstationId) {
        return { actionCreator: 'doResetActiveWorkstation' };
      }

      return null;
    },
  ),

  reactShouldLoadWorkstationColumns: createSelector(
    'selectWorkstationColumns',
    workstationColumns => {
      if (workstationColumns.length > 0) return null;
      return { actionCreator: 'doLoadWorkstationColumns' };
    },
  ),
};
