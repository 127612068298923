import { createSelector } from 'redux-bundler';
import { chain, flatten, forEachRight, groupBy, mapValues } from 'lodash';
import moment from 'moment-timezone';

import PasswordChange from '../models/password_change';

export default {
  name: 'password_changes',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      return state;
    };
  },

  doResetPassword: password => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state);
    const user = store.selectAuthenticatedUser(state);
    const cua = user.clientUserAssignments.find(
      cua => cua.clientId === client.id,
    );

    const pc = new PasswordChange({
      clientUserAssignment: cua,
      user,
      password: password,
    });
    await pc.save({ with: ['clientUserAssignment.id', 'user.id'] });
    return pc;
  },
};
