import Workstation from '../models/workstation';
import WorkstationCreationGroup from '../models/workstation_creation_group.ts';
import WorkstationGroupAssignment from '../models/workstation_group_assignment';

export default {
  name: 'workstationCreationGroups',
  getReducer: () => {
    const initialData = {};

    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => state;
  },

  doCreateWorkstationCreationGroup: values => async ({
    dispatch,
    store,
    getState,
  }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const overrideImage = store.selectOverrideImage(state);
    const workstations = values.workstations.map(
      v =>
        new Workstation({
          ...v,
          workstationGroupAssignments: values.groups.map(
            group => new WorkstationGroupAssignment({ group }),
          ),
        }),
    );

    const workstationCreationGroup = new WorkstationCreationGroup({
      ...values,
      facility,
      workstations,
      overrideImage,
    });

    await workstationCreationGroup.save({
      with: [
        'facility.id',
        'template.id',
        'overrideImage.id',
        { workstations: { workstationGroupAssignments: 'group.id' } },
      ],
    });

    if (workstationCreationGroup.isPersisted) {
      facility.requiresUpdate = true;
      dispatch({ type: 'UPDATE_FACILITY', payload: { facility } });

      const { id: clientId } = store.selectRouteParams(getState());
      store.action('doFetchWorkstations', [{ client_id: clientId }]);
    }

    return workstationCreationGroup;
  },
};
