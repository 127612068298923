import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationBoot extends ApplicationRecord {
  static jsonapiType = 'workstation_boots';
  @Attr() state: string;

  @BelongsTo() workstation: Workstation;
}
