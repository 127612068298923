import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class ConnectionManager extends ApplicationRecord {
  static jsonapiType = 'connection_managers';
  @Attr() status: string;
  @Attr() instanceType: string;
  @Attr() certificateExpiresAt: string;

  @BelongsTo() facility: Facility;
}
