import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import cx from 'classnames';

export default withStyles(theme => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    // borderWidth: '1px',
    // borderStyle: 'solid',
    borderColor: theme.palette.divider,
    // marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,

      // marginBottom: theme.spacing(3),
    },
  },
  fullHeight: {
    height: '100%',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
}))(({ classes, bottomMargin, fullHeight, className, ...props }) => (
  <Paper
    elevation={1}
    {...props}
    className={cx([
      className,
      bottomMargin && classes.bottomMargin,
      fullHeight && classes.fullHeight,
    ])}
    classes={classes}
  />
));
