import { Attr, BelongsTo, HasMany, HasOne, Model, Link } from 'spraypaint';
import moment from 'moment';

import { ApplicationRecord } from './application_record';
import AvailabilityZone from './availability_zone';
import Facility from './facility';
import Group from './group';
import Image from './image';
import InstanceType from './instance_type';
import Template from './template';
import WorkstationConnection from './workstation_connection';
import WorkstationCreation from './workstation_creation';
import WorkstationCreationGroup from './workstation_creation_group';
import WorkstationDeletion from './workstation_deletion';
import WorkstationEventFeedEntry from './workstation_event_feed_entry';
import WorkstationGroupAssignment from './workstation_group_assignment';
import WorkstationLogin from './workstation_login';
// import WorkstationStat from './workstation_stat';
import Stat from './stat';

@Model()
export default class Workstation extends ApplicationRecord {
  static jsonapiType = 'workstations';
  @Attr() name: string;
  @Attr() ipAddress: string;
  @Attr() instanceId: string;
  @Attr() stackId: string;
  @Attr() stackName: string;
  @Attr() isFailed: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() pending: boolean;
  @Attr() multiAz: boolean;
  @Attr() ebsRaid: boolean;
  @Attr() instanceState: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() discardedAt: string;
  @Attr() availabilityZoneIdentifier: string;
  @Attr() ssmConnectionCommand: string;
  @Attr() pcoipActive: boolean;

  @Attr() render: boolean;

  @Attr() pendingIceBoot: boolean;
  @Attr() pendingIceBootAttempts: number;
  @Attr() maneAgentBuildVersion: string;
  @Attr() maneAgentUpdatePending: boolean;
  @Attr() idleShutdownEnabled: boolean;

  @Attr() lastLoginAt: string;
  @Attr() lastLoginUsername: string;

  @Attr() totalHours: number;
  @Attr() recentHours: number;
  @Attr() mtdHours: number;

  @BelongsTo() creationGroup: WorkstationCreationGroup;
  @BelongsTo() instanceType: InstanceType;
  @BelongsTo() image: Image;
  @BelongsTo() template: Template;
  @BelongsTo() facility: Facility;
  @BelongsTo() availabilityZone: AvailabilityZone;
  @HasOne() creation: WorkstationCreation;
  @HasOne() deletion: WorkstationDeletion;
  @HasOne() pendingDeletion: WorkstationDeletion;
  @HasOne() lastLogin: WorkstationLogin;
  @HasOne() lastConnection: WorkstationConnection;
  @HasMany() events: WorkstationEventFeedEntry;
  @HasMany() workstationGroupAssignments: WorkstationGroupAssignment[];
  @HasMany() groups: Group[];
  @HasOne() cpuStat: Stat;
  @HasOne() workstationHoursStat: Stat;

  @Link() delete: string;
  @Link() boot: string;
  @Link() shutdown: string;
  @Link() reboot: string;

  constructor(attrs?: Record<string, any>) {
    const defaults = { selected: false };
    super({ ...defaults, ...attrs });
  }

  isStopped() {
    return this.instanceState === 'stopped';
  }

  isRunning() {
    return this.instanceState === 'running';
  }

  lastUserInfo() {
    if (this.lastLoginUsername === null) return '-';
    return `${this.lastLoginUsername} - ${moment
      .utc(this.lastLoginAt)
      .local()
      .calendar()}`;
  }

  hasGroups() {
    return this.groups.length > 0;
  }

  iceMessage() {
    if (this.isRunning()) {
      return 'Your workstation has been re-provisioned. Please wait whilst we complete reconfiguration...';
    }

    if (this.pendingIceBootAttempts > 0) {
      return `Please wait, attempting to provision workstation. ${this.pendingIceBootAttempts} attempts remaining...`;
    }

    if (this.pendingIceBootAttempts === 0 && this.multiAz) {
      return `Please wait, attempting to find capacity...`;
    }
  }

  screens(): any[] {
    if (!(this.lastConnection && this.lastConnection.screens)) {
      return [];
    }

    return this.lastConnection.screens.map(screen => {
      const [x, y] = screen.split(' x ');
      return [parseInt(x), parseInt(y)];
    });
  }

  formattedCreatedAt() {
    return this.createdAt
      ? moment
          .utc(this.createdAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }
}
