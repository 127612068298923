import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';

@Model()
export default class CrossAccountRoleInstallation extends ApplicationRecord {
  static jsonapiType = 'cross_account_role_installations';
  @Attr() partOfOrganisation: boolean;
  @Attr() createdAt: string;
  @Attr() roles: object;
  @Attr() licenses: object;
  @BelongsTo() account: Account;
}
